<template>
  <div>
    <div v-if="nowIndex === 0">
      <div class="right_header" style="position: relative">
        <Row :gutter="16">
          <Col span="5">
            <span>需求名称</span>
            <Input v-model="params.title" />
          </Col>
          <Col span="8">
            <span>需求提列时间</span>
            <Row :gutter="16">
              <Col span="11">
                <DatePicker
                  type="date"
                  :options="startOption"
                  :transfer="true"
                  v-model="startTime"
                  ref="startTime"
                ></DatePicker>
              </Col>
              <Col span="2"
                ><span style="margin-bottom: 0; margin-top: 8px">至</span></Col
              >
              <Col span="11">
                <DatePicker
                  type="date"
                  :options="endOption"
                  :transfer="true"
                  ref="endTime"
                  v-model="endTime"
                ></DatePicker>
              </Col>
            </Row>
          </Col>
          <Col span="4">
            <span>需求类型</span>
            <Select
              v-model="params.requirementType"
              :transfer="true"
            >
              <!--              <option value="0">委员会评审</option>-->
                <Option value="">全部</Option>
                <Option
                    v-for="(option, index) in optionsList"
                    :value="option.requirementType"
                    :key="index"
                >{{ option.requirementTypeName }}</Option
                >

            </Select>
          </Col>
          <Col span="7">
            <div
              class="button_style"
              style=" position: relative; top: 50%"
              @click="search()"
            >
              检索
            </div>
            <div
              class="button_style"
              style="position: relative; top: 50%; background-color: #fda736;margin-left: 13px"
              @click="addDemand()"
            >
              +新增需求
            </div>
          </Col>
        </Row>
      </div>
      <div class="right_real_content" style="position: relative">
        <Table
          border
          :columns="columns12"
          :data="dataList"
          :disabled-hover="true"
        >
          <template slot-scope="{ row, list }" slot="action">
            <div v-if="row.requirementType === 'TECHNICAL_AUTOMATIC_MATCHING'">
              <Button
                type="primary"
                size="small"
                style="margin-right: 5px; color: rgb(55, 197, 207)"
                @click="viewReport(row)"
                >查看报告
              </Button>
              <span class="operation_span">|</span>
              <Button
                type="error"
                size="small"
                @click="deleteDemand(row)"
                style="color: rgb(55, 197, 207)"
                >删除需求</Button
              >
            </div>
            <div v-if="row.requirementType !== 'TECHNICAL_AUTOMATIC_MATCHING'">
              <Button
                  v-if="row.replayStatus == '已回复'"
                type="primary"
                size="small"
                style="margin-right: 5px; color: rgb(55, 197, 207)"
                @click="seeReplay(row)"
                >查看回复
              </Button>
              <span class="operation_span"  v-if="row.replayStatus == '已回复'">|</span>
              <Button
                type="error"
                size="small"
                @click="deleteDemand(row)"
                style="color: rgb(55, 197, 207)"
                >删除需求</Button
              >
            </div>
          </template>
        </Table>
        <Page
          :total="total"
          show-elevator
          style="margin-top: 50px; float: right;margin-bottom: 20px"
          @on-change="changePage"
        />
      </div>
    </div>

    <div v-if="nowIndex === 1">
      <div style="height: auto; padding-bottom: 30px; border-bottom: none" class="common_liucheng">
        <span
          >推荐科研专家
          <div class="arrow_ld">
            <span @click="backPage('expert')"
              ><Icon type="ios-arrow-back"
            /></span>
            <span @click="forwardPage('expert')"
              style="margin-left: 10px"><Icon type="ios-arrow-forward"
            /></span></div
        ></span>
        <div class="neirong">
          <div class="nei_rong_box">
            <Row :gutter="16">
              <Col span="6" v-for="(item, index) in expertList" :key="index"   >
                <div
                    class="result-patent-hover"
                  style="
                    height: auto;
                    border: 1px solid rgb(218, 226, 229);
                    margin-bottom: 20px;
                  "
                >
                  <div
                    style="
                      height: 90px;
                      background-color: rgb(247, 248, 252);
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span
                      style="
                        font-size: 18px;
                        display: inline-block;
                        margin-left: 10px;
                        margin-right: 5px;
                      "
                      >{{ item.expertName }}</span
                    ><span style="font-size: 14px">{{ item.unitsName }}</span>
                  </div>
                  <div style="height: 130px">
                    <div
                      style="
                        height: 60px;
                        display: flex;
                        align-items: center;
                        margin-left: 10px;
                      "
                    >
                      推荐指数：<span>{{ item.expertsCount }}</span>
                    </div>
                    <div
                      style="
                        height: 42px;
                        padding-left: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                      "
                    >
                      <span>
                        技术关键词：{{item.keywords || '暂无'}}
                      </span>
                    </div>
                  </div>
                  <div
                      @click.stop="openZoosUrl"
                      style="
                    cursor: pointer;
                    height: 39px;
                    text-align: center;
                    line-height: 39px;
                    color: white;
                    background-color: rgb(55, 197, 207);
                    border: none;
                  "
                  >
                    联系专家
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div style="height: auto; padding-bottom: 30px;border-bottom: none" class="common_liucheng">
        <span
          >推荐科研成果
          <div class="arrow_ld">
            <span @click="backPage('result')"
              ><Icon type="ios-arrow-back"
            /></span>
            <span @click="forwardPage('result')"
              style="margin-left: 10px"><Icon type="ios-arrow-forward"
            /></span></div
        ></span>
        <div class="neirong">
          <div class="nei_rong_box">
            <Row :gutter="16">
              <Col span="6" v-for="(item, index) in resultList" :key="index" >
                <div
                    @click="seeResult(item)"
                    class="result-patent-hover"
                    style="
                    height: auto;
                    border: 1px solid rgb(218, 226, 229);
                    margin-bottom: 20px;
                  "
                >
                  <div
                    style="
                      height: 90px;
                      background-color: rgb(247, 248, 252);
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="span_cg">{{ item.title }}</span>
                    <span style="font-size: 14px" class="span_yyjs">{{
                      item.achieveType
                    }}</span>
                  </div>
                  <div style="height: 130px">
                    <div
                        style="
                      margin-top: 20px;
                      height: 50px;
                      display: flex;
                      align-items: center;
                      margin-left: 10px;
                             overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    "
                    >
                    <span
                        style="
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        line-height: 25px;
                        text-align: left;
                      "
                    >归属高校：{{ item.colleges }}</span
                    >
                    </div>
                    <div
                      style="
                        height: 42px;
                        padding-left: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                      "
                    >
                      技术关键词：{{ item.keywords }}
                    </div>
                  </div>
                  <div
                      style="
                    cursor: pointer;
                    height: 39px;
                    text-align: center;
                    line-height: 39px;
                    color: white;
                    background-color: rgb(55, 197, 207);
                    border: none;
                  "
                      @click.stop="openZoosUrl"
                  >
                    联系成果
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div style="height: auto; border-bottom: none" class="common_liucheng">
        <span
          >推荐科研专利
          <div class="arrow_ld">
            <span @click="backPage('patent')"
              ><Icon type="ios-arrow-back"
            /></span>
            <span @click="forwardPage('patent')"
              style="margin-left: 10px"><Icon type="ios-arrow-forward"
            /></span></div
        ></span>
        <div class="neirong">
          <div class="nei_rong_box" style="padding-bottom: 30px" >
            <Row :gutter="16">
              <Col span="6" v-for="(item, index) in patentList" :key="index" >
                <div
                    @click="seePatent(item)"
                    class="result-patent-hover"
                  style="

                    height: auto;
                    border: 1px solid rgb(218, 226, 229);
                    margin-bottom: 20px;
                  "
                >
                  <div
                    style="
                      height: 90px;
                      background-color: rgb(247, 248, 252);
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="span_cg">{{ item.ti }}</span>
                    <span
                      style="font-size: 14px; width: 50px"
                      class="span_yyjs"
                      >{{ item.lastLegalStatus }}</span
                    >
                  </div>
                  <div style="height: 205px">
                    <div
                      style="
                        height: 50px;
                        display: flex;
                        align-items: center;
                        margin-left: 10px;
                      "
                    >
                      <span
                        style="
                          display: inline-block;
                          width: 100px;
                          text-align: left;
                        "
                        >专利申请号：</span
                      ><span>{{ item.an }}</span>
                    </div>
                    <div
                      style="
                        height: 50px;
                        display: flex;
                        align-items: center;
                        margin-left: 10px;
                      "
                    >
                      <span
                        style="
                          display: inline-block;
                          width: 100px;
                          text-align: left;
                        "
                        >专利类型：</span
                      ><span>{{ item.patType }}</span>
                    </div>
                    <div
                        style="
                        height: 50px;
                        margin-left: 10px;
                                 text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                                overflow: hidden;
                      "
                    >
                      <span
                          style="
                          text-align: left;
                                 height: 50px;
                                  line-height: 25px;
                                 text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                                overflow: hidden;
                        "
                      >归属高校：{{ item.pa }}</span
                      >
                    </div>
                    <div
                        style="
                      height: 50px;
                      align-items: center;
                      margin-left: 10px;

                    "
                    >
                    <span
                        style="
                        display: inline-block;
                overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        text-align: left;
                      "
                    >成果发明人：{{ item.pin }}</span
                    >
                    </div>
                  </div>
                  <div
                      @click.stop="openZoosUrl"
                      style="
                    cursor: pointer;
                    height: 39px;
                    text-align: center;
                    line-height: 39px;
                    color: white;
                    background-color: rgb(55, 197, 207);
                    border: none;
                  "
                  >
                    联系专利
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div
        class="common_liucheng"
        style="
          height: auto;
          padding-bottom: 30px;
          display: flex;
          align-items: center;
          border-bottom: none !important;
        "
      >
        <div style="height: 370px; width: 60%; margin-top: 100px">
          <table
            class="qiye"
            border="0"
            cellspacing="0"
            cellpadding="0"
            border-collapse="collapse"
          >
            <tr style="height: 50px">
              <td style="width: 100px">需求标题：</td>
              <td colspan="3">{{ detailItem.title }}</td>
            </tr>
            <tr style="height: 50px; background-color: #f3fcfc">
              <td style="width: 100px">需求日期：</td>
              <td colspan="3">{{ detailItem.createdTime }}</td>
            </tr>
            <tr style="height: 50px">
              <td style="width: 100px">需求类型：</td>
              <td colspan="3">{{ detailItem.requirementTypeName }}</td>
            </tr>
            <tr style="height: 220px">
              <td style="width: 100px; background-color: #f3fcfc">
                需求描述：
              </td>
              <td colspan="3">{{ detailItem.content }}</td>
            </tr>
          </table>
        </div>
        <div
          style="height: 370px; width: 40%; margin-top: 100px"
          ref="wd"
        ></div>
      </div>
    </div>
    <Modal
      id="ReplayModal"
      v-model="ReplayModal"
      title="回复详情"
      width="800"
      :footer-hide="footHide"
    >
      <div>
        <div
          id="replay"
          style="
            background-color: rgb(245, 246, 250);
            position: relative;
            padding: 20px;
            margin-bottom: 50px;
          "
        >
          <div style="font-weight: bold; margin-left: 20px">回复内容：</div>
          <div
            style="
              width: 90%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              margin-bottom: 30px;
            "
          >
            <span v-if="replayItem.replyContent">
              {{ replayItem.replyContent }}
            </span>
            <span v-else> 尚未回复 </span>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="deleteModal"
      title="删除需求"
      width="400"
      :footer-hide="footHide"
    >
      <div>
        <div style="padding: 20px">
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
          >
            <div style="margin-bottom: 30px">
              <span>您确定删除该需求？</span>
            </div>
            <div>
              <div
                class="button_style"
                style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                @click="delCancel()"
              >
                我再想想
              </div>
              <div
                class="button_style"
                style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                  background-color: #fda736;
                "
                @click="confirmDel()"
              >
                确定
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="addModal"
      ok-text="提交"
      cancel-text="取消"
      width="800"
      title="新增需求"
    >
      <div
        style="margin-top: 40px; margin-bottom: 40px; border-bottom: none"
        class="div_center"
      >
        <Form :model="addDemandForm" :label-width="100">
          <FormItem label="需求类型:">
            <CheckboxGroup
              v-model="addDemandForm.demandType"
              @on-change="dataChange"
            >
              <Checkbox
                :label="item.requirementTypeName"
                v-for="(item, index) in optionsList"
                :key="index"
              ></Checkbox>
            </CheckboxGroup>
          </FormItem>
          <FormItem label="需求标题:">
            <Input
              v-model="addDemandForm.title"
              placeholder="请输入需求标题"
            ></Input>
          </FormItem>
          <FormItem label="需求内容:">
            <Input
              placeholder="请输入需求内容"
              v-model="addDemandForm.content"
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 5 }"
            ></Input>
          </FormItem>
          <FormItem label="需求标签:">
            <Input
              v-model="addDemandForm.label"
              placeholder="请输入需求标签"
            ></Input>
          </FormItem>
        </Form>
      </div>
      <div style="text-align: left; margin-top: 30px; margin-left: 60px">
        <div
          class="button_style"
          style="margin-left: 26px; width: 80px; position: relative; top: 50%"
          @click="submitAdd()"
        >
          提交需求
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getDemandList,
  getDemandType,
  addDemand,
  delDemand,
  getExpertMatch,
  getPatentMatch,
  getResultsMatch,
  getWordCloud,
} from "../../../plugins/api/EnterpriseSchoolConnection";
import {
  isCorrectPassword,
  isPhoneNumber,
  successToast,
  warnToast,
  errorToast,
} from "@/plugins/tools/util";
export default {
  name: "EnterpriseDemand",
  watch: {
    startTime() {
      let self = this;
      this.endOption = {
        disabledDate(date) {
          if (self.startTime) {
            return date && date.valueOf() < self.startTime;
          }
        },
      };
    },
    endTime() {
      let self = this;
      this.startOption = {
        disabledDate(date) {
          if (self.endTime) {
            return date && date.valueOf() > self.endTime;
          }
        },
      };
    },
  },

  mounted() {
    this.getDemand();
    this.getDemandTypeList();
  },

  data() {
    return {
      nowIndex: 0,
      replayItem: {}, // 回复的item
      deleteId: "",
      total: 10,
      matchParams: {
        requestId: "",
        pageSize: 8,
        pageNum: 1,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        title: "",
        startTime: "",
        endTime: "",
        requirementType: "",
      },
      optionsList: [],
      addModal: false,
      deleteModal: false,
      ReplayModal: false,
      startOption: {},
      endOption: {},
      startTime: "",
      endTime: "",
      footHide: false,
      data7: [
        {
          name: "John Brown",
          age: 18,
          address: "New York No. 1 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
      ],
      cloudData: [
      ],
      addDemandForm: {
        demandType: [],
        title: "",
        content: "",
        label: "",
      },
      addDemandFormSecond: {
        demandType: [],
        title: "",
        content: "",
        label: "",
      },
      patentTotal: 0,
      patentNow: 1,
      resultTotal: 0,
      resultNow: 1,
      expertTotal: 0,
      expertNow: 1,
      patentList: [],
      resultList: [],
      expertList: [],
      dataList: [],
      detailItem: {},
      columns12: [
        {
          title: "需求时间",
          key: "createdTime",
          align: "center",
        },
        {
          title: "需求标题",
          key: "title",
          align: "center",
        },
        {
          title:"回复状态",
          key:'replayStatus',
          align: 'center'
        },
        {
          title: "需求类型",
          key: "requirementTypeName",
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
    };
  },
  methods: {
    expertDetail(item){
      let routeUrl = this.$router.resolve({
        path: "/home/experts",//新页面地址
        query: { shopId: item.id }//携带的参数
      });
      window.open(routeUrl.href, "_blank");
    },
    openZoosUrl() {
      openZoosUrl('chatwin');
    },
    async backPage(name) {
      switch (name) {
        case "result":
          if (this.resultNow === 1) {
            return "";
          } else {
            this.resultNow -= 1;
            this.matchParams.pageNum = this.resultNow;
            let rjson = await getResultsMatch(this.matchParams);
            if (rjson.code == 0) {
              this.resultList = rjson.result.list;
              this.resultTotal = rjson.result.totalPage;
            } else {
              errorToast(rjson.message);
            }
          }
          break;
        case "expert":
          if (this.expertNow === 1) {
            return "";
          } else {
            this.expertNow -= 1;
            this.matchParams.pageNum = this.expertNow;
            let rjson = await getExpertMatch(this.matchParams);
            if (rjson.code === 0) {
              this.expertList = rjson.result.list;
              this.expertTotal = rjson.result.totalPage;
            } else {
              errorToast(rjson.message);
            }
          }
          break;
        case "patent":
          if (this.patentNow === 1) {
            return "";
          } else {
            this.patentNow -= 1;
            this.matchParams.pageNum = this.patentNow;
            let rjson = await getPatentMatch(this.matchParams);
            if (rjson.code === 0) {
              this.patentList = rjson.result.list;
              this.patentTotal = rjson.result.totalPage;
            } else {
              errorToast(rjson.message);
            }
          }
          break;
      }
    },
    async forwardPage(name) {
      switch (name) {
        case "result":
          if (this.resultNow === this.resultTotal) {
            return "";
          } else {
            this.resultNow += 1;
            this.matchParams.pageNum = this.resultNow;
            let rjson = await getResultsMatch(this.matchParams);
            if (rjson.code === 0) {
              this.resultList = rjson.result.list;
              this.resultTotal = rjson.result.totalPage;
            } else {
              errorToast(rjson.message);
            }
          }
          break;
        case "expert":
          if (this.expertNow === this.resultTotal) {
            return "";
          } else {
            this.expertNow += 1;
            this.matchParams.pageNum = this.expertNow;
            let rjson = await getExpertMatch(this.matchParams);
            if (rjson.code == 0) {
              this.expertList = rjson.result.list;
              this.expertTotal = rjson.result.totalPage;
            } else {
              errorToast(rjson.message);
            }
          }
          break;
        case "patent":
          if (this.patentNow === this.patentTotal) {
            return "";
          } else {
            this.patentNow += 1;
            this.matchParams.pageNum = this.patentNow;
            let rjson = await getPatentMatch(this.matchParams);
            if (rjson.code == 0) {
              this.patentList = rjson.result.list;
              this.patentTotal = rjson.result.totalPage;
            } else {
              errorToast(rjson.message);
            }
          }
          break;
      }
    },
    // 画词云
    drawCloud(wrapEl, data) {
      let myChart = this.$echarts.init(wrapEl);
      var option = {
        tooltip: {
          show: true,
        },
        series: [
          {
            name: "热词",
            type: "wordCloud",
            sizeRange: [10, 30],
            rotationRange: [-20, 20],
            shape: "circle",
            left: "center",
            top: "center",
            width: "100%",
            height: "100%",
            gridSize: 3,
            textPadding: 0,
            autoSize: {
              enable: true,
              minSize: 6,
            },
            textStyle: {
              normal: {
                color: function () {
                  return (
                    "rgb(" +
                    [
                      Math.round(Math.random() * 250),
                      Math.round(Math.random() * 250),
                      Math.round(Math.random() * 250),
                    ].join(",") +
                    ")"
                  );
                },
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: "#333",
              },
            },
            data: data,
          },
        ],
      };
      myChart.setOption(option);
    },
    // 多选边单选
    dataChange(data) {
      if (data.length > 1) {
        this.addDemandForm.demandType = [];
        let index = String(data.length - 1);
        this.addDemandForm.demandType = [data[index]];
      }
    },
    changeOption() {},
    addDemand() {
      this.addModal = true;
    },
    // 提交需求
    async submitAdd() {
      this.optionsList.forEach((item, index) => {
        if (item.requirementTypeName === this.addDemandForm.demandType[0]) {
          this.addDemandForm.demandType[0] = item.requirementType;
        }
      });
      let submitForm = {};
      submitForm.requirementType = this.addDemandForm.demandType[0];
      submitForm.title = this.addDemandForm.title;
      submitForm.content = this.addDemandForm.content;
      submitForm.label = this.addDemandForm.label;
      if (!this.addDemandForm.content) {
        warnToast("请填写需求内容");
        return;
      }
      if (!this.addDemandForm.demandType) {
        warnToast("请填写需求类型");
        return;
      }
      if (!this.addDemandForm.label) {
        warnToast("请填写需求标签");
        return;
      }
      if (!this.addDemandForm.title) {
        warnToast("请填写需求标题");
        return;
      }
      let json = await addDemand(submitForm);
      if (json.code === 0) {
        successToast("添加成功");
        this.getDemand();
      } else {
        warnToast(json.message);
      }
      this.addModal = false;
      for(let item in this.addDemandForm){
        if(item != 'demandType'){
        this.addDemandForm[item] = ""
        }else{
          this.addDemandForm[item] = [];
        }
      }
    },

    //得到类型列表
    async getDemandTypeList() {
      let json = await getDemandType();
      if (json.code === 0) {
        this.optionsList = JSON.parse(JSON.stringify(json.result));

      }
    },

    // 搜索
    async search() {
      this.params.pageNum = 1;
      debugger
      if (this.startTime) {
        this.params.startTime = this.startTime.getTime();
      } else {
        this.params.startTime = "";
      }
      if (this.endTime) {
        this.params.endTime = this.endTime.getTime();
      } else {
        this.params.endTime = "";
      }
      this.getDemand();
    },

    //得到需求列表
    async getDemand() {
      let json = await getDemandList(this.params);
      if (json.code === 0) {
        this.dataList = json.result.list;
        this.dataList.forEach((item, index) => {
          if(item.requirementType == 'TECHNICAL_AUTOMATIC_MATCHING'){
            item.replayStatus = '--'
          }else{
            if(item.replyContent){
              item.replayStatus = "已回复"
            }else{
              item.replayStatus = "未回复"
            }
          }

          let dateee = new Date(item.createdTime).toJSON();
          item.createdTime = new Date(+new Date(dateee) + 8 * 3600 * 1000)
            .toISOString()
            .replace(/T/g, " ")
            .replace(/\.[\d]{3}Z/, "");
        });
        this.total = json.result.total
      }

    },

    // 翻页
    async changePage(index) {
      this.params.pageNum = index;
      await this.getDemand();
    },

    // 删除
    deleteDemand(item) {
      this.deleteId = item.id;
      this.deleteModal = true;
    },
    delCancel() {
      this.deleteModal = false;
    },
    async confirmDel() {
      let data = {
        ids: this.deleteId,
      };
      let json = await delDemand(data);
      if (json.code === 0) {
        successToast("删除成功");
        this.getDemand();
      } else {
        warnToast(json.message);
      }
      this.deleteModal = false;
    },
    color: function () {
      return (
          "rgb(" +
          [
            Math.round(Math.random() * 250),
            Math.round(Math.random() * 250),
            Math.round(Math.random() * 250),
          ].join(",") +
          ")"
      );
    },
    async viewReport(row) {
      this.detailItem = row;
      this.matchParams.requestId = row.id;
      let json = await getPatentMatch(this.matchParams);
      if (json.code == 0) {
        this.patentList = json.result.list;
        this.patentTotal = json.result.totalPage;
      } else {
        errorToast(json.message);
      }

      let rjson = await getResultsMatch(this.matchParams);
      if (rjson.code == 0) {
        this.resultList = rjson.result.list;
        this.resultTotal = rjson.result.totalPage;
      } else {
        errorToast(rjson.message);
      }
      let ejson = await getExpertMatch(this.matchParams);
      if (ejson.code == 0) {
        this.expertList = ejson.result.list;
        this.expertTotal = ejson.result.totalPage;
      } else {
        errorToast(ejson.message);
      }

      let wordCloudList = await getWordCloud({ requestId: row.id });
      if(wordCloudList.code == 0){
        if(wordCloudList.result.length > 0){
          wordCloudList.result.forEach((item,index) => {
            this.cloudData.push( { value: Math.round(Math.random() * 250), name: item.name, textStyle: { color: this.color() } })
          })
        }else{
          this.cloudData.push( { value: Math.round(Math.random() * 250), name: "无", textStyle: { color: this.color()}})
        }
      }

      this.nowIndex = 1;
      // this.$parent.changeHeight();
      this.$nextTick(()=> {
        this.drawCloud(this.$refs.wd,this.cloudData)
      })

      scrollTo(0, 0);
    },
    seePatent(item){
      let routeUrl = this.$router.resolve({
        path: "/home/ResourceDetail",//新页面地址
        query: { an: item.an }//携带的参数
      });
      window.open(routeUrl.href, "_blank");

    },
    seeResult(item){
      let routeUrl = this.$router.resolve({
        path: "/home/PatentSearchDetail",//新页面地址
        query: { id: item.id }//携带的参数
      });
      window.open(routeUrl.href, "_blank");
    },
    seeReplay(row) {
      this.replayItem = row;
      this.ReplayModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
/*/deep/ .ivu-modal-header-inner {*/
/*text-align: center;*/
/*background-color: rgb(245, 246, 250)!important;*/
/*}*/

/*/deep/ .ivu-modal-header {*/
/*background-color: rgb(245, 246, 250)!important;*/
/*}*/
/*/deep/ .ivu-modal-footer {*/
/*display: none!important;*/
/*}*/
/*/deep/ .ivu-rate-text {*/
/*display: none;*/
/*}*/

/deep/ .ivu-select-single .ivu-select-selection .ivu-select-placeholder,
.ivu-select-single .ivu-select-selection .ivu-select-selected-value {
  line-height: 40px !important;
}

/deep/ .ivu-table-tbody tr:nth-child(2n) td {
  background-color: #f3fcfc !important;
}

/deep/ td {
}

.button_style {
  display: inline-block;
  background-color: rgb(54, 194, 207);
  width: 100px;
  height: 40px;
  z-index: 51;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: white;
  cursor: pointer;
}

.input_search,
.input_search:focus {
  border-width: 1px;
  border-color: rgb(55, 197, 207);
  border-style: solid;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  width: 300px;
  height: 40px;
  outline: none;
}

.right_real_content {
  margin-bottom: 50px;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
}

.ivu-select-selection {
  border-radius: 0 !important;
}
.arrow_ld {
  display: inline-block;
  position: absolute;
  right: 0;
  & > span {
    display: inline-block;
    height: 20px;
    width: 20px;
    cursor: pointer;
    background-color: #cecece;
    text-align: center;
    line-height: 20px;
    color: white;
  }
  & > span:hover{
    background-color: #37c5cf;
  }
}
.qiye,
.qiye td {
  border: 1px solid rgb(54, 194, 207);
}
.qiye {
  border-bottom: none;
  border-right: none;
  width: 100%;
}
.qiye td {
  border-top: none;
  border-left: none;
  text-align: center;
}
.span_yyjs {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  height: 24px;
  line-height: 20px;
  width: 64px;
  border-width: 1px;
  background-color: #fcdfc8;
  border-color: #fea464;
  border-style: solid;
  padding: 2px;
  color: black;
  opacity: 0.8;
  overflow: hidden;
  position: absolute;
  right: 20px;
}
.qiye td:nth-child(1) {
  width: 100px;
  text-align: right;
}

.qiye td:nth-child(2) {
  width: 310px;
}

.qiye td:nth-child(3) {
  width: 130px;
}

.qiye td:nth-child(4) {
  width: 260px;
}

.span_cg {
  font-size: 18px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.common_liucheng {
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #dfe0f2 !important;
  & > span {
    display: block;
    padding-left: 5px;
    padding-top: 38px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    color: rgb(51, 51, 51);
    margin-bottom: 30px;
  }
}

.next_style {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_real_content {
  margin-bottom: 50px;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
}
.right_header {
  width: 95%;
  padding-top: 20px;
  height: 140px;
  position: relative;
  margin-bottom: 40px !important;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #f5f5f5;
  overflow: hidden;

  span {
    display: block;
    margin-bottom: 20px;
  }
}

/deep/ th,
.ivu-table-cell-slot > strong {
  text-align: center !important;
}
.result-patent-hover:hover {
  box-shadow: 6px 6px 15px 0px rgba(127, 127, 127, 0.3);
}
</style>
